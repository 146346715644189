<template>
  <div class="login d-flex">
 <div v-if="loginMainImage==='' || loginMainImage===undefined || loginMainImage===null" class="bg"></div>
  <div v-else class="bg1"   :style="{ background: 'url(' + loginMainImage + ')no-repeat center center / cover' }"></div>
    <div class="loginBox d-flex justify-center align-center">
            <!-- langange change feature  -->
            <div class="language-dropdown" v-if="setFetchLanguageListflag ">
        <div> {{ $t('Choose Your Language') }} </div>
        <div class="h_lang drop-down">
          <button type="button" @click="toggleDropdown" class="btn-dropdown">
            {{ currentLanguage }}
          </button>
          <ul class="drop-box custom-scrollBox" style="height:200px;overflow-y:hide">
            <li v-for="language in  languageList " style="cursor: pointer;padding:10px"
              :style="{ backgroundColor: currentLanguage === language.languageName ? '#C1C1C1' : '' }" :key="language.id"
              @click="setActive(language)">
              {{ language.languageName }}
            </li>
          </ul>
        </div>
      </div>
      <!-- langange change feature  -->

      <v-card class="loginForm" flat>
        <v-card-title class="login-tit justify-center">{{
          $t('ESL DASHBOARD')
        }}</v-card-title>
          <v-card-subtitle v-show="!keycloakloginform" class="login-txt mt-1">{{
          $t('Welcome back! Please login to your account.')
        }}</v-card-subtitle>
         <v-card-subtitle v-show="keycloakloginform" class="login-txt mt-1" style="text-align:center">{{
          $t('Welcome back!')
        }}</v-card-subtitle>
        <v-card-text class="mt-7" v-show="!keycloakloginform">
          <v-form>
            <v-select
              v-model="loginMode"
              :hint="`${loginMode.code}, ${loginMode.name}`"
              :items="loginModeItems"
              item-text="name"
              item-value="code"
              return-object
              class="form-select"
              outlined
              dense
              solo
            >
            <template v-slot:item="{item }">
              <span style="font-size: 0.8125rem;">{{ $t(item.name) }}</span>
          </template>
          <template v-slot:selection="{item }">
              <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.name) }}</span>
          </template>
          </v-select>
            <v-text-field
              ref="account"
              v-model="user.account"
              :label="$t('ID')"
              class="form-login"
              clearable

            />
            <v-text-field
              ref="password"
              v-model="user.password"
              @keyup.enter="onLogin"
              :label="$t('Password')"
              type="password"
              class="form-login"
              clearable
            />
            <v-text-field
              v-model="user.domain"
              label="Domain"
              v-show="domainInputShow"
              class="form-login"
              clearable
            />
            <!-- <v-checkbox
              v-model="staySignedIn"
              label="Stay Signed In"
              color="indigo"
              value="indigo"
              hide-details
            ></v-checkbox> -->
            <div class="d-flex justify-space-between align-center">
            <v-checkbox
                v-model="staySignedIn"
                :label="$t('Stay Signed In')"
                class="form-checkbox _login"
                hide-details
              ></v-checkbox>
              <v-dialog v-model="dialog" width="500" v-if="!domainInputShow">
                <template  v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text class="link">{{
                    $t('Forgot Password?')
                  }}</v-btn>
                </template>
                <v-card class="popup add_store_popup">
                  <h3 class="page-title-bar">
                    <v-icon size="25" color="#001e38">mdi-lock</v-icon>{{ $t('Forgot your Password?') }}
                  </h3>
                  <!-- <p class="guide_txt">
                    {{
                      $t(
                        'Answer the following questions to reset your password'
                      )
                    }}.
                  </p> -->
                  <v-row class="mt-5">
                    <v-col>
                      <v-text-field
                       v-model="account"
                        outlined
                        dense
                        hide-details
                        clearable
                        label="User ID*"
                        class="form-input"
                      ></v-text-field>
                    </v-col>
                      <v-btn  @click="passwordReset" text icon class="btn mt-10" style="line-height: 37px!important;">{{
                      $t('Send Email')
                    }}</v-btn>
                  </v-row>
                  <span style="color: red;">
                    {{passwordText}}
                  </span>
                  <!-- <v-row>
                    <v-col>
                      <p>{{ $t('Security Question 2') }} *</p>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        clearable
                        label="What is your father’s name?"
                        class="form-input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>{{ $t('Security Question 3') }} *</p>
                      <v-text-field
                        class="form-input"
                        label="What is your dream job?"
                        outlined
                        dense
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                  <v-card-actions class="d-flex justify-center">
                    <v-btn @click="closePasswordPopup" text icon class="btn" style="line-height: 37px !important;">{{
                      $t('Close')
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-form>
        </v-card-text>
        <p v-if="licenseExpired" class="txt-activation">
          <img src="@/assets/img/loginerror.png" alt="" />
          License has expired. Please activate license.
        </p>
        <v-btn
          v-if="licenseExpired"
          @click="showLoginLicenseActivation"
          class="btn-activation"
          text
          >License Activation
        </v-btn>
        <license-activation
          :license-expired="licenseExpired"
          @messageAlert="messageAlert"
          @hideActivateLicenseBtn="hideActivateLicenseBtn"
        />
        <p v-show="passwordExpired" class="txt-activation">
          <img src="@/assets/img/loginerror.png" alt="" />
          User's password has expired.
        </p>
        <v-btn
          v-if="passwordExpired"
          @click="showLoginPasswordChange"
          class="btn-activation"
          >{{ $t('Change Password') }}</v-btn
        >
        <password-change
          :account="passwordExpiredAccount"
          :passwordexpired="passwordExpired"
          @messageAlert="messageAlert"
          @hideChangePasswordBtn="hideChangePasswordBtn"
        />
        <v-btn @click="onLogin" v-show="!keycloakloginform" class="btn-login">{{ $t('Login') }}</v-btn>
      </v-card>
      <p class="copyright">
         <span v-if="loginLogoImage==='' || loginLogoImage===undefined  || loginLogoImage===null ">
          <img src="@/assets/img/logo-login.jpg" alt="solum" /><br />{{ $t('@2015-2022 SOLUM Proprietary and Confidential')}}
        </span>
        <span v-else><img :src="loginLogoImage" alt="solum"  style="width:148px;height:26px"/><br />{{ $t('@2015-2022 SOLUM Proprietary and Confidential')}}</span>
      </p>
    </div>
    <!--Message Box-->
    <div>
      <v-dialog v-model="messageDialog" width="300">
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row align="center">
            <v-col>
              <span v-text="messageText"></span>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center" v-if="!okBtn">
            <v-btn text icon @click="doForceLogin" class="btn" max-width="100"
              >{{$t('Yes')}}</v-btn
            >
            <v-btn text icon @click="messageDialog = false" class="btn" max-width="100"
              >{{$t('Cancel')}}</v-btn
            >
          </v-card-actions>
          <v-card-actions class="d-flex justify-center" v-if="okBtn">
            <v-btn text icon @click="messageDialog = false;keycloakRedirect()" class="btn"
              >{{$t('OK')}}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Loading Box -->
    <loading-box :loadingBox="loadingBox" />
  </div>
</template>

<script>
// Utility
import User from '@/models/user'
import { signIn } from '@/adLogin/authPopup'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'

// <!-- langange change feature  -->
import codes from '@/plugins/codes'
// <!-- langange change feature  -->

// Components
import LicenseActivation from '@/views/LoginLicenseActivation'
import PasswordChange from '@/views/LoginPasswordChange'
// Mixins
import LoadingBoxParent from '@/mixins/LoadingBoxParent'

// keycloak
import Keycloak from 'keycloak-js'
import Vue from 'vue'
// For autoLogin and Redirection
import { decryptAES } from '@/plugins/crypto'
// import VueLogger from 'vuejs-logger'

const msgs = {
  passwordExpired: 'Your password was expired.'
}
const requests = {
  getCustomImageApi: {
    method: 'get',
    url: '/common/config/imageList'
  },
  passwordresetApi: {
    method: 'put',
    url: '/common/account/password/reset'
  },
  toenableLCDtab: {
    method: 'get',
    url: '/api/common/lcd/check'
  }

}

export default {
  name: 'Login',
  mixins: [LoadingBoxParent],
  components: {
    LicenseActivation,
    PasswordChange
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      dialog: false,
      messageDialog: false,
      messageText: '',
      loginModeItems: [
        { code: 'local', name: this.$t('Local Mode Login') },
        { code: 'domain', name: this.$t('Domain Mode Login') }
      ],
      loginMode: { code: 'local', name: this.$t('Local Mode Login') },
      domainInputShow: false,
      user: new User('', ''),
      licenseExpired: false,
      passwordExpired: false,
      passwordExpiredAccount: '',
      staySignedIn: false,
      forceLogin: false,
      okBtn: false,
      doForceBtn: false,
      loginLogoImage: {},
      loginMainImage: {},
      keycloakloginform: false,
      errorcode: '',
      account: '',
      passwordText: '',
      // <!-- langange change feature  -->
      currentLanguage: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
      languageList: [],
      setFetchLanguageListflag: false
      // <!-- langange change feature  -->

    }
  },
  methods: {

    passwordReset () {
      console.log(this.account)
      this.passwordText = ''
      const url = configs.ApiUrl + requests.passwordresetApi.url
      console.log(url)
      if (this.account !== '') {
        this.openLoadingBox(this.$t('Loading'))
        const body = {
          account: this.account
        }
        const config = { params: { } }
        this.$utils
          .callAxiosWithBody(
            requests.passwordresetApi.method,
            url,
            body,
            config
          )
          .then(res => {
            this.closeLoadingBox()
            console.log(res)
            this.passwordText = '*' + res.data.responseMessage
          })
          .catch(error => {
            this.closeLoadingBox()
            console.log(error.response.data.responseMessage)
            this.passwordText = '*' + error.response.data.responseMessage
          })
      } else {
        this.passwordText = '* Enter the UserID'
      }
    },
    closePasswordPopup () {
      this.dialog = false
      this.passwordText = ''
      this.account = ''
      this.$forceUpdate()
    },
    messageAlert (text) {
      this.messageText = text
      this.messageDialog = true
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    onLogin (account = undefined, password = undefined) {
      this.okBtn = false
      try {
        this.openLoadingBox(this.$t('Loading'))
        if (!account || !password) {
          if (commons.isNull(this.user.account)) {
            this.$refs.account.$el.getElementsByTagName('input')[0].focus()
            return
          }
          if (commons.isNull(this.user.password)) {
            this.$refs.password.$el.getElementsByTagName('input')[0].focus()
            return
          }
        }
        this.user.loginMode = this.loginMode.code
        this.user.force = this.forceLogin
        // updating the reqbody based on the query params we got in the url
        // and decrypting the user and password
        const reqBody = account && password ? {
          ...this.user, account: account, password: password
        } : this.user
        this.$store
          .dispatch('auth/login', reqBody)
          .then(async res => {
            this.$store.dispatch(
              'dataStore/updateStaySignedIn',
              this.staySignedIn
            )
            this.$store.dispatch('dataStore/updateSidebarMenu', '')
            this.$store.dispatch('dataStore/updateActivateFeatures', res.activateFeatures)
            this.$store.dispatch('dataStore/updatePermissionKey', res.permissionKey)
            this.$store.dispatch('dataStore/updatePermissionValue', res.permissionValue)
            // SSE Config
            if (res.rabbitMqEnable) {
              this.$store.dispatch('dataStore/updaterabbitMqEnable', res.rabbitMqEnable)
            } else {
              this.$store.dispatch('dataStore/updaterabbitMqEnable', 'false')
            }
            // SSE Config
            // LCD  tab for PICKCEL
            if (res.lcdType) {
              this.$store.dispatch('dataStore/UPDATE_LCD_TYPE', res.lcdType)
            } else {
              this.$store.dispatch('dataStore/UPDATE_LCD_TYPE', 'NONE')
            }
            this.$store.dispatch('dataStore/INIT_SELECTED_STORE', this.$store)

            // Automatic store selection if Single store mapped with user
            if (res.managedStores.length === 1) {
              const selectedStore = {
                company: res.managedStores[0].company,
                country: res.managedStores[0].country,
                region: res.managedStores[0].region,
                city: res.managedStores[0].city,
                code: res.managedStores[0].code,
                name: res.managedStores[0].name
              }
              this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', selectedStore)
            }
            // End

            this.toenableLCDtab()
            // this.$router.push('/').catch(() => {})
          })
          .catch(error => {
            this.loading = false
            this.messageText = this.$t(error.responseMessage || 'Failed to login')
            this.messageDialog = true
            this.errorcode = error.responseCode
            switch (error.responseCode) {
              case '401':
                if (error.responseMessage === msgs.passwordExpired) {
                  this.passwordExpiredAccount = this.user.account
                  this.passwordExpired = true
                }
                this.okBtn = true
                break
              case '402':
                this.licenseExpired = true
                break
              case '444':
                this.okBtn = false
                this.doForceBtn = true
                break
              default:
                this.okBtn = true
                break
            }
          })
      } catch (error) {
        console.debug(`The login proccess has failed. error: ${error}`)
      } finally {
        this.closeLoadingBox()
      }
    },
    // onLogin () {
    //   this.okBtn = false
    //   this.doForceBtn = false
    //   try {
    //     this.openLoadingBox(this.$t('Loading'))
    //     if (commons.isNull(this.user.account)) {
    //       this.$refs.account.$el.getElementsByTagName('input')[0].focus()
    //       return
    //     }
    //     if (commons.isNull(this.user.password)) {
    //       this.$refs.password.$el.getElementsByTagName('input')[0].focus()
    //       return
    //     }
    //     this.user.loginMode = this.loginMode.code
    //     this.user.force = this.forceLogin
    //     this.$store
    //       .dispatch('auth/login', this.user)
    //       .then(async res => {
    //         this.$store.dispatch(
    //           'dataStore/updateStaySignedIn',
    //           this.staySignedIn
    //         )
    //         this.$store.dispatch('dataStore/updateSidebarMenu', '')
    //         this.$store.dispatch('dataStore/updateActivateFeatures', res.activateFeatures)
    //         this.$store.dispatch('dataStore/updatePermissionKey', res.permissionKey)
    //         this.$store.dispatch('dataStore/updatePermissionValue', res.permissionValue)
    //         // SSE Config
    //         if (res.rabbitMqEnable) {
    //           this.$store.dispatch('dataStore/updaterabbitMqEnable', res.rabbitMqEnable)
    //         } else {
    //           this.$store.dispatch('dataStore/updaterabbitMqEnable', 'false')
    //         }
    //         // SSE Config

    //         // LCD  tab for PICKCEL
    //         if (res.lcdType) {
    //           this.$store.dispatch('dataStore/UPDATE_LCD_TYPE', res.lcdType)
    //         } else {
    //           this.$store.dispatch('dataStore/UPDATE_LCD_TYPE', 'NONE')
    //         }
    //         this.$store.dispatch('dataStore/INIT_SELECTED_STORE', this.$store)

    //         // Automatic store selection if Single store mapped with user
    //         if (res.managedStores.length === 1) {
    //           const selectedStore = {
    //             company: res.managedStores[0].company,
    //             country: res.managedStores[0].country,
    //             region: res.managedStores[0].region,
    //             city: res.managedStores[0].city,
    //             code: res.managedStores[0].code,
    //             name: res.managedStores[0].name
    //           }
    //           this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', selectedStore)
    //         }
    //         // End

    //         this.toenableLCDtab()
    //         // this.$router.push('/').catch(() => {})
    //       })
    //       .catch(error => {
    //         this.loading = false
    //         this.messageText = this.$t(error.responseMessage || 'Failed to login')
    //         this.messageDialog = true
    //         this.errorcode = error.responseCode
    //         switch (error.responseCode) {
    //           case '401':
    //             if (error.responseMessage === msgs.passwordExpired) {
    //               this.passwordExpiredAccount = this.user.account
    //               this.passwordExpired = true
    //             }
    //             this.okBtn = true
    //             break
    //           case '402':
    //             this.licenseExpired = true
    //             break
    //           case '444':
    //             this.okBtn = false
    //             this.doForceBtn = true
    //             break
    //           default:
    //             this.okBtn = true
    //             break
    //         }
    //       })
    //   } catch (error) {
    //     console.debug(`The login proccess has failed. error: ${error}`)
    //   } finally {
    //     this.closeLoadingBox()
    //   }
    // },
    toenableLCDtab () {
      this.openLoadingBox(this.$t('Loading'))
      const config = { params: {} }
      this.$utils
        .callAxios(
          requests.toenableLCDtab.method,
          requests.toenableLCDtab.url,
          config
        )
        .then(res => {
          const data = res.data
          console.log(data)
          localStorage.setItem('LcdTabEnable', data[0].lcdenable)
        })
        .catch(err => {
          console.log(err)
          localStorage.setItem('LcdTabEnable', 'false')
          this.$forceUpdate()
        }).finally(() => {
          this.closeLoadingBox()
          // differenciating where to route based on page query param
          const page = this.$route.query?.page
          if (page === undefined) {
            // got to the overview page
            this.$router.push(`/${this.$route.query?.code ? '?code=' + this.$route.query?.code : ''}`).catch((error) => { console.log('error', error) })
          } else if (page) {
            const queryParams = { ...this.$route.query }
            delete queryParams.data
            delete queryParams.page
            // updating the selected store from managedstores
            const store = this.$store.state.dataStore.managedStores.find((st) => st.code === queryParams.code)
            if (store) { this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', store) }
            // base on page param it will go to either Label Info or Gateway info page with filters on
            for (const qp in queryParams) {
              if (queryParams[qp].startsWith('[')) { queryParams[qp] = JSON.parse(queryParams[qp].replace(/([A-Z]+)/g, '"$1"')) }
            }
            this.$router.push({ name: this.$route.query?.page, params: queryParams }).catch((error) => { console.log('error', error) })
          }
        })
    },
    // toenableLCDtab () {
    //   this.openLoadingBox(this.$t('Loading'))
    //   const config = { params: {} }
    //   this.$utils
    //     .callAxios(
    //       requests.toenableLCDtab.method,
    //       requests.toenableLCDtab.url,
    //       config
    //     )
    //     .then(res => {
    //       const data = res.data
    //       console.log(data)
    //       localStorage.setItem('LcdTabEnable', data[0].lcdenable)
    //       this.$router.push('/').catch(() => {})
    //       this.closeLoadingBox()
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       localStorage.setItem('LcdTabEnable', 'false')
    //       this.$router.push('/').catch(() => {})
    //       this.closeLoadingBox()
    //       this.$forceUpdate()
    //     })
    // },
    // Keycloak login page Redirection based on API Response
    keycloakRedirect () {
      if (configs.keycloakLogin === true && this.errorcode === '401') {
        const logoutUrl = configs.keycloakurl + '/realms/' + configs.keycloakrealm + '/protocol/openid-connect/logout?post_logout_redirect_uri=' + window.location.origin + process.env.BASE_URL + 'login'
        window.location.href = logoutUrl
      }
    },
    hideActivateLicenseBtn () {
      this.licenseExpired = false
      this.okBtn = true
    },
    hideChangePasswordBtn () {
      this.passwordExpired = false
    },
    showLoginLicenseActivation () {
      EventBus.$emit('showLoginLicenseActivation')
    },
    showLoginPasswordChange () {
      EventBus.$emit('showLoginPasswordChange')
    },
    doForceLogin () {
      if (this.doForceBtn === false) {
        this.messageDialog = false
        this.showLoginLicenseActivation()
        this.licenseExpired = false
      } else {
        this.forceLogin = true
        this.messageDialog = false
        this.onLogin()
      }
    },
    // idleLogout for local server Keycloak
    idleLogout (idletime) {
      var idle
      window.onload = resetTimer
      window.onmousemove = resetTimer
      window.onmousedown = resetTimer // catches touchscreen presses as well
      window.ontouchstart = resetTimer // catches touchscreen swipes as well
      window.onclick = resetTimer // catches touchpad clicks as well
      window.onkeydown = resetTimer
      window.scroll = resetTimer
      window.addEventListener('scroll', resetTimer, true) // improved; see comments
      function yourFunction () {
        const logoutUrl = configs.keycloakurl + '/realms/' + configs.keycloakrealm + '/protocol/openid-connect/logout?post_logout_redirect_uri=' + window.location.origin + process.env.BASE_URL + 'login'
        window.location.href = logoutUrl
      }
      function resetTimer () {
        clearTimeout(idle)
        idle = setTimeout(yourFunction, idletime * 1000) // time is in MileSeconds
      }
    },
    // <!-- langange change feature  -->
    toggleDropdown (event) {
      this.setFetchLanguageList()
      event.target.classList.toggle('isOpenend')
      // 09.27 버튼 외 화면 클릭시 메뉴 안보이게 처리
      document.addEventListener('click', function (e) {
        if (e.target !== event.target) {
          event.target.classList.remove('isOpenend')
        }
      })
    },
    setFetchLanguageList () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.getLanguageList.method,
          codes.requests.getLanguageList.url,
          config
        )
        .then((res) => {
          if (res.data) {
            this.setFetchLanguageListflag = true
            this.languageList = res.data.languageList
            const userSubscriptionKey = this.languageList[0].languageId
            const indexOfObject = this.languageList.findIndex(
              (o) => o.languageId === userSubscriptionKey
            )
            if (localStorage.getItem('languagecontent') === null) {
              this.setActive(this.languageList[0], indexOfObject)
            }
            if (localStorage.getItem('languagecontent') !== null) {
              const obj = {
                languageName: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
              }
              const datavalue = this.languageList.filter(function (person) { return person.languageName === obj.languageName })
              this.togetlanguagecontent(datavalue[0].languageId)
            }
          }
        })
        .catch((error) => {
          this.setFetchLanguageListflag = false
          console.warn(`Could not find any Language List : ${error}`)
        })
    },

    // Active class
    setActive (subscription) {
      this.currentLanguage = subscription.languageName
      this.togetlanguagecontent(subscription.languageId)
    },
    // To get language content
    togetlanguagecontent (id) {
      // })
      // if (code === undefined) {
      //   code = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      // }
      const config = {
        params: {
          languageId: id,
          export: null,
          contents: true
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getLanguageContent.method,
          codes.requests.getLanguageContent.url,
          config
        )
        .then((res) => {
          if (res.data) {
            localStorage.setItem('languagecontent', res.data.content)
            this.$i18n.locale = 'lan'
            this.$i18n._vm._data.messages.lan = JSON.parse(localStorage.getItem('languagecontent'))
            this.currentLanguage = res.data.languageName
            this.$store.dispatch('dataStore/UPDATE_CURRENT_LANGUAGE', res.data.languageName)
            this.$forceUpdate()
          }
        })
        .catch((error) => {
          console.warn(`Could not find any Language content : ${error}`)
        })
    }
    // <!-- langange change feature  -->

  },
  watch: {
    loginMode (val) {
      if (this.loginMode.code === 'domain') {
        this.domainInputShow = true
      } else {
        this.domainInputShow = false
      }
    }
  },
  created () {
    commons.clearVuex()
  },
  mounted () {
    // <!-- langange change feature  -->
    this.setFetchLanguageList()
    // <!-- End langange change feature  -->
    // For autoLogin and Redirection
    if (this.$route.query?.data) {
      // calling the login function without showing the user and password field
      // if got user and password in the query params
      this.forceLogin = true
      this.keycloakloginform = true
      const { name, beta } = JSON.parse(decryptAES(this.$route.query?.data))
      this.onLogin(name, beta)
    }
    this.$refs.account.focus()
    sessionStorage.setItem('tabIndex', 0)
    localStorage.setItem('LcdTabEnable', 'false')
    if (this.productionType === 'cloud') {
      signIn(this.user).then(async account => {
        this.user.account = account
        await this.onLogin()
      })
    }
    // KeyCloak Based Login
    if (configs.keycloakLogin === true) {
      this.keycloakloginform = true
      // keycloak integrations
      const initOptions = {
        url: configs.keycloakurl,
        realm: configs.keycloakrealm,
        clientId: configs.keycloakclientId,
        onLoad: configs.keycloakonLoad,
        'ssl-required': 'none'
      }
      const keycloak = Keycloak(initOptions)
      keycloak
        .init({ onLoad: initOptions.onLoad, checkLoginIframe: false })
        .then(async (auth) => {
          if (!auth) {
            window.location.reload()
          } else {
            this.tokenexpiry = keycloak.idTokenParsed.exp - keycloak.idTokenParsed.iat
            this.idletime = keycloak.refreshTokenParsed.exp - keycloak.refreshTokenParsed.iat
            console.log(this.idletime)
            console.log(this.tokenexpiry)
            this.idleLogout(this.idletime)
            this.$store.commit('auth/UPDATE_TOKEN', keycloak.token)
            await this.onLogin()
          }
          // Token Refresh
          setInterval(() => {
            keycloak.updateToken(this.idletime * 1000)
              .then((refreshed) => {
                if (refreshed) {
                  this.$store.commit('auth/UPDATE_TOKEN', keycloak.token)
                }
              })
              .catch(() => {
                Vue.$log.error('Failed to refresh token')
              })
          }, this.tokenexpiry * 1000)
        })
        .catch(() => {
          //
        })
    }
    /// end keycloak
    this.clearTabindex()
    if (configs.ApiUrl === undefined || configs.ApiUrl === 'undefined' || configs.ApiUrl === '' || configs.ApiUrl === null) {
      this.$store.dispatch('dataStore/updateMenuLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginMainImage', '')
    }
    const url = configs.ApiUrl + requests.getCustomImageApi.url
    this.$utils.callAxios(requests.getCustomImageApi.method, url).then(async (res) => {
      this.$store.dispatch('dataStore/updateMenuLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginMainImage', '')
      if (res.status === 200) {
        var getImageList = res.data.imageList
        for (var i = 0; i < getImageList.length; i++) {
          if (getImageList[i].location === 'LoginMainImage') {
            this.$store.dispatch('dataStore/updateLoginMainImage', getImageList[i].fileData)
          } if (getImageList[i].location === 'LoginLogoImage') {
            this.$store.dispatch('dataStore/updateLoginLogoImage', getImageList[i].fileData)
          } if (getImageList[i].location === 'MenuLogoImage') {
            this.$store.dispatch('dataStore/updateMenuLogoImage', getImageList[i].fileData)
          }
        }
      } else {
        this.$store.dispatch('dataStore/updateMenuLogoImage', '')
        this.$store.dispatch('dataStore/updateLoginLogoImage', '')
        this.$store.dispatch('dataStore/updateLoginMainImage', '')
      }
    }).catch(error => {
      console.debug(error)
      this.$store.dispatch('dataStore/updateMenuLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginMainImage', '')
    })
    this.loginLogoImage = this.$store.state.dataStore.LoginLogoImage
    this.loginMainImage = this.$store.state.dataStore.LoginMainImage
  }
}
</script>

<style scoped>
.login {
  height: 100%;
}
.login .bg {
  width: 50%;
  background: url(../assets/img/bg-login.jpg) no-repeat center center / cover;
}
.login .bg1 {
  width: 50% !important;
  /* background-size: 750px 750px !important */
}
.login .loginBox {
  position: relative;
  width: 50%;
}
.login .loginBox .loginForm {
  width: auto;
}
.login .loginBox .loginForm .login-tit {
  font-size: var(--font40);
  font-weight: 700;
  color: #001e38;
  letter-spacing: 0.18em;
}
.login .loginBox .loginForm .login-txt {
  font-size: var(--font20);
  color: #4d4f5c;
}
.v-btn.link {
  padding: 0;
  font-size: var(--font18);
  color: #43425d;
  text-transform: none;
  letter-spacing: 0;
}
.form-login {
  font-size: var(--font20);
}
.form-login .v-label {
  font-size: var(--font20) !important;
}
.btn-login {
  display: block;
  width: 150px;
  height: 40px !important;
  margin: 8px auto 0;
  background: #001e38 !important;
  color: #fff;
  border-radius: 5px;
  font-size: var(--font16);
}
.btn-activation {
  display: block;
  width: 150px;
  height: 40px !important;
  margin: 8px auto 0;
  background: #e27070 !important;
  color: #fff;
  border-radius: 5px;
  font-size: var(--font16);
}
.txt-activation {
  margin: 10px 0 20px;
  color: #e27070;
  font-size: var(--font18);
  text-align: center;
}
.copyright {
  position: absolute;
  bottom: 5vh;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  font-size: var(--font11);
  color: #43425d;
}
.copyright img {
  margin-bottom: 5px;
}
.guide_txt {
  font-size: var(--font22);
  font-weight: 700;
}
/*langange change feature*/
.drop-down {
  position: relative;
}

.btn-dropdown:after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 8px;
  margin-left: 10px;
  background: url(../assets/img/ico-dropdown.png) no-repeat top left;
}

.drop-box {
  display: none;
  position: absolute;
  top: 100%;
  padding: 0;
  border: 1px solid #d7dcdf;
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  height: 200px;
  z-index: 1;
  width: 100%;
  left: 0px;
  margin-top: 1px;
}

.drop-down {
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
}

.language-dropdown {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  align-self: end;
  top: 0px;
  right: 0px;
  position: absolute;
}

.isOpenend:after {
  transform: rotate(180deg);
}

.isOpenend+.drop-box {
  display: block;
}
/*langange change feature*/

</style>
<style>
.LedPatternClass + input{
  display: contents!important;
}
</style>
